<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="20" :offset="2">
        <el-card class="box-card">
          <p class="text-2xl pb-12">Airport Transport</p>
          <el-form label-width="auto" label-position="top" size="large">
            <el-form-item label="Please select your transport type">
              <el-radio-group v-model="form.tripType" size="large">
                <el-radio-button label="One Way" />
                <el-radio-button label="Return" />
              </el-radio-group>
            </el-form-item>
            <div v-if="form.tripType === 'One Way'">
              <el-form-item
                label="Please select your planned arrival dates and times"
              >
                <el-date-picker
                  v-model="form.oneWayTrip"
                  size="large"
                  type="datetime"
                  placeholder="Arrival Date-Time"
                />
              </el-form-item>
            </div>
            <div v-else-if="form.tripType === 'Return'">
              <el-form-item
                label="Please select your planned arrival and departure dates and times"
              >
                <el-date-picker
                  v-model="form.returnTrip"
                  size="large"
                  type="datetimerange"
                  placeholder="enter your date"
                  start-placeholder="Arrival Date-Time"
                  end-placeholder="Deperture Date-Time"
                  :default-time="defaultTime2"
                />
              </el-form-item>
            </div>

            <el-form-item
              label="Please enter your locations that where you will come and go"
            >
              <div class="flex">
                <MazInput
                  v-model="form.fromLocation"
                  placeholder=""
                  hint="From"
                  class="pr-3"
                />
                <MazInput v-model="form.toLocation" placeholder="" hint="To" />
              </div>
            </el-form-item>
            <el-form-item label="Group Size(Person)">
              <el-slider
                v-model="form.groupSize"
                :step="1"
                max="20"
                show-input
                size="large"
                input-size="large"
                show-stops
              />
            </el-form-item>
            <el-form-item label="Please Select Vehicle Type">
              <el-row class="">
                <el-col class="p-2" :span="8">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="@/assets/car-types/mercedes-class.jpeg"
                      class="image"
                    />
                    <div style="padding: 14px">
                      <el-radio
                        v-model="form.carType"
                        label="class"
                        size="large"
                        border
                        >Mercedes Class</el-radio
                      >
                      <div class="bottom"></div>
                    </div>
                  </el-card>
                </el-col>
                <el-col class="p-2" :span="8">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="@/assets/car-types/mercedes-sprinter.jpeg"
                      class="image"
                    />
                    <div style="padding: 14px">
                      <el-radio
                        v-model="form.carType"
                        label="sprinter"
                        size="large"
                        border
                        >Mercedes Sprinter</el-radio
                      >
                      <div class="bottom"></div>
                    </div>
                  </el-card>
                </el-col>
                <el-col class="p-2" :span="8">
                  <el-card :body-style="{ padding: '0px' }">
                    <img
                      src="@/assets/car-types/mercedes-vito.jpeg"
                      class="image"
                    />
                    <div style="padding: 14px">
                      <el-radio
                        v-model="form.carType"
                        label="vito"
                        size="large"
                        border
                        >Mercedes Vito</el-radio
                      >
                      <div class="bottom"></div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="Full Name">
              <el-input
                v-model="form.name"
                placeholder="Please enter your name"
              />
            </el-form-item>
            <el-form-item label="Contact Informations">
              <el-input
                v-model="form.email"
                placeholder="Please enter your email"
              />
            </el-form-item>
            <MazPhoneNumberInput
              size="md"
              countriesHeight="20"
              v-model="form.phone.phoneNumber"
              @update="form.phone.results = $event"
              class="mb-2"
            />
            <el-form-item label="Do you use Whatsapp with this number?">
              <el-switch
                v-model="form.whatsapp"
                class="mb-2"
                active-text="Yes"
                inactive-text="No"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </el-form-item>
            <el-form-item label="Notes">
              <el-input v-model="form.notes" type="textarea" />
            </el-form-item>
            <el-form-item label="GDPR Consent">
              <el-checkbox v-model="form.gdpr" label="" size="large" border
                >Agree on our terms and condition for <br />
                using your submitted data?</el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-alert
                title="Please be sure that your information correct"
                type="warning"
                center
                show-icon
                :closable="false"
              />
            </el-form-item>
          </el-form>

          <el-button
            class="pt-4 submit-btn"
            size="large"
            type="success"
            @click="submitForm()"
            >Send Your Request</el-button
          >
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      form: {
        tripType: null,
        oneWayTrip: null,
        returnTrip: null,
        fromLocation: null,
        toLocation: null,
        groupSize: null,
        carType: null,
        name: null,
        email: null,
        phone: {
          phoneNumber: null,
          results: null,
        },
        whatsapp: null,
        notes: null,
        gdpr: null,
      },
      defaultTime1: [new Date(2000, 1, 1, 12, 0, 0)],
      defaultTime2: [
        new Date(2000, 1, 1, 12, 0, 0),
        new Date(2000, 2, 1, 8, 0, 0),
      ],
    };
  },
  methods: {
    submitForm() {
      console.log(this);
      const config = {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.loaded);
          Swal.fire({
            title: "Processing",
            text: 'Please wait...',
            icon: "info",
          });
        },
      };
      axios
        .post("https://api.truebluetour.com/api/airport-transport-form/submit", this.form,config)
        .then((res) => {
          Swal.fire({
            title: "Message",
            text: res.data.ticketId,
            icon: "success",
            confirmButtonText: "Ok",
          });
          setTimeout(function () {
            window.location.replace("./thank-you/" + res.data.ticketId);
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
.image {
  width: 100%;
  display: block;
}
.submit-btn{
  background-color: #67c23a !important;
}
</style>
